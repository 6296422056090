// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
Rails.start();
import Turbolinks from 'turbolinks';
Turbolinks.start();

import 'jquery-uniform/dist/jquery.uniform.min.js';
import 'select2/dist/js/select2.full';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/modal';
import 'bootstrap/js/collapse';
import datepickerFactory from 'jquery-datepicker/jquery-datepicker';
import moment from 'moment';
window.moment = moment;

datepickerFactory($);

import 'javascript/vendor/space';
window.TS = {};
require('javascript/ts_app/tableDataTables');
require('javascript/ts_app/forms');
require('javascript/ts_app/validator');
require('javascript/ts_app/graphs');
require('javascript/ts_app/timeout');
require('javascript/ts_app/triggerFullReloadWhenJsError');
require('javascript/ts_app/resetBeforeCache');
require('javascript/ts_app/conditionize');
require('javascript/ts_app/signature');
import { copyOldVisit } from '../javascript/ts_app/copy_old_visit';

if (Turbolinks.supported) {
  // show for any page that takes longer than 500 ms to load
  Turbolinks.setProgressBarDelay(500);
}

window.TS.setUpValidator();

// FontAwesome.config.mutateApproach = 'sync';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

document.addEventListener('turbolinks:load', copyOldVisit);
